.background-dialog-tasks {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    background-color: rgba(245, 245, 245, 0.4);
    z-index: 2;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.background-dialog-tasks.show {
    opacity: 1;
    transform: scale(1);
}

.background-dialog-tasks.closing {
    opacity: 0;
    transform: scale(0.9);
}

@media (min-width: 350px) and (max-width: 400px) {

    .dialog-tasks-add {
        width: 90%;
        margin: 100px 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 20px;
    }

    .dialog-tasks-view {
        width: 90%;
        margin: 100px 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 20px;
    }
    
}

@media (min-width: 400px) {

    .dialog-tasks-add {
        width: 350px;
        height: auto;
        margin: 20vh auto;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 20px;
    }

    .dialog-tasks-view {
        width: 350px;
        height: auto;
        margin: 20vh auto;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 20px;
    }
    
}

.dialog-tasks-add h1 {
    text-align: center;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.dialog-tasks-add .container {
    width: 100%;
    margin: 30px 0;
}

.dialog-tasks-add .container .input-box {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.dialog-tasks-add .container .input-box label {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.dialog-tasks-add .container .input-box input {
    width: 100%;
    height: 35px;
    padding: 10px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border: 1px solid #cfcfcf;
    border-radius: 6px;
    outline: none;
}

.dialog-tasks-add .container .input-box textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border: 1px solid #cfcfcf;
    border-radius: 6px;
    outline: none;
    resize: none;
}

.dialog-tasks-add .container .input-box select {
    width: 100%;
    height: 35px;
    padding: 0 5px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border: 1px solid #cfcfcf;
    border-radius: 6px;
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none;
    outline: none;
}

.dialog-tasks-add .container .container-btn {
    width: 100%;
    margin-top: 40px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog-tasks-add .container .container-btn button {
    width: 48%;
    height: 35px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    background-color: #333;
    color: #ffffff;
    cursor: pointer;
}

.dialog-tasks-add .container .container-btn button:nth-child(1) {
    color: #333;
    background-color: transparent;
    border: 2px solid #333;
}

.dialog-tasks-view h1 {
    text-align: center;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.dialog-tasks-view .container {
    width: 100%;
    margin: 30px 0;
}

.dialog-tasks-view .container .input-box {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.dialog-tasks-view .container .input-box label {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.dialog-tasks-view .container .input-box input {
    width: 100%;
    height: 35px;
    padding: 10px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border: 1px solid #cfcfcf;
    border-radius: 6px;
    outline: none;
    cursor: default;
}

.dialog-tasks-view .container .input-box textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border: 1px solid #cfcfcf;
    border-radius: 6px;
    outline: none;
    resize: none;
    cursor: default;
}

.dialog-tasks-view .container .container-btn {
    width: 100%;
    margin-top: 40px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog-tasks-view .container .container-btn button {
    width: 48%;
    height: 35px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    background-color: #6fbe7a;
    color: #ffffff;
    cursor: pointer;
}


.dialog-tasks-view .container .container-btn button:nth-child(1) {
    color: #333333;
    background-color: transparent;
    border: 2px solid #333;
}