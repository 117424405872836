.background-dialog-hours {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    background-color: rgba(245, 245, 245, 0.4);
    z-index: 2;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.background-dialog-hours.show {
    opacity: 1;
    transform: scale(1);
}

.background-dialog-hours.closing {
    opacity: 0;
    transform: scale(0.9);
}

@media (min-width: 350px) and (max-width: 400px) {

    .dialog-hours-modify {
        width: 90%;
        height: auto;
        margin: 100px 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 20px;
    }
}

@media (min-width: 400px) {

    .dialog-hours-modify {
        width: 350px;
        height: auto;
        margin: 20vh auto;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 20px;
    } 
}

.dialog-hours-modify h1 {
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.dialog-hours-modify .input-box {
    display: flex;
    flex-direction: column;
    margin: 20px 0 30px 0;
}

.dialog-hours-modify .container-input-time {
    position: relative;
}

.dialog-hours-modify .container-input-time input {
    width: 100%;
    height: 35px;
    padding: 10px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border: 1px solid #cfcfcf;
    border-radius: 6px;
    outline: none;
}

.dialog-hours-modify .container-input-time span {
    position: absolute;
    height: 100%;
    width: 35px; 
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    border-left: 1px solid #cfcfcf;
    color: #333;
    font-size: 18px;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-hours-modify .input-box label {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 5px;
}

.dialog-hours-modify .input-box select {
    width: 100%;
    height: 35px;
    padding: 0 5px;
    font-size: 14px;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    border: 1px solid #cfcfcf;
    border-radius: 6px;
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none;
    outline: none;
}

.dialog-hours-modify .container-btn {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog-hours-modify .container-btn button {
    width: 48%;
    height: 100%;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    background-color: #333;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-hours-modify .container-btn button:nth-child(1) {
    color: #333;
    background-color: transparent;
    border: 2px solid #333;
}