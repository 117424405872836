@media (max-width: 1000px) {

    header {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: end;
        border-bottom: 1px solid #e2e2e2;
        background-color: #f5f5f5;
        z-index: 100;
    }

    header .container-logo {
        position: absolute;
        left: 30px;
    }

    header .container-logo img {
        width: 60px;
        height: auto;
        object-fit: contain;
    }
    
    header .menu-icon {
        color: #333;
        font-size: 170%;
        cursor: pointer;
    }

    header .container-nav {
        position: absolute;
        top: 50%;
        right: -100%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        z-index: 105;
        transition: right 0.2s ease-in-out; 
        overflow-y: none;
    }
    
    header .container-nav.visible {
        right: 0;
    }

    header .container-nav .container-close-icon {
        width: 100%;
        height: 80px;
        padding: 0 30px;
        font-size: 170%;
        margin-bottom: 50px;
        display: flex;
        justify-content: end;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid #e2e2e2;
    }

    header ul {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: row;
    }

    header li {
        list-style: none;
        margin: 20px auto;
        font-size: 120%;
    }

    header ul .link  {
        text-decoration: none;
        color: #333;
        font-size: 105%;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: pointer;
    }
}

@media (min-width: 1000px) {

    header {
        position: relative;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: end;
        border-bottom: 1px solid #e2e2e2;
        background-color: #f5f5f5;
        z-index: 100;
    }

    header .container-logo {
        position: absolute;
        left: 30px;
    }

    header .container-logo img {
        width: 60px;
        height: auto;
        object-fit: contain;
    }
    
    .menu-icon {
        display: none;
    }

    .container-close-icon {
        display: none;
    }

    header ul {
        width: auto;
        height: 100%;
        margin-right: 30px;
        display: flex;
        align-items: center;
    }

    header ul .container-nav {
        display: flex;
        flex-direction: row;
    }

    header li {
        list-style: none;
    }

    header ul .link  {
        text-decoration: none;
        margin-left: 30px;
        color: #333;
        font-size: 105%;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: pointer;
    }
}