.background-dialog-user-hours {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    background-color: rgba(245, 245, 245, 0.4);
    z-index: 2;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.background-dialog-user-hours.show {
    opacity: 1;
    transform: scale(1);
}

.background-dialog-user-hours.closing {
    opacity: 0;
    transform: scale(0.9);
}

@media (min-width: 350px) and (max-width: 400px) {

    .background-dialog-user-hours .container-hours {
        width: 90%;
        margin: 100px 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 20px;
    }
}

@media (min-width: 400px) {

    .background-dialog-user-hours .container-hours {
        width: 300px;
        height: auto;
        margin: 20vh auto;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 20px;
    }
}

.background-dialog-user-hours .container-hours p {
    text-align: left;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.background-dialog-user-hours .container-hours span {
    text-align: left;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.background-dialog-user-hours .container-hours .container-btn {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: end;
    justify-content: end;
}

.background-dialog-user-hours .container-hours .container-btn button {
    width: 48%;
    height: 35px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #ffffff;
    background-color: #333;
    border: none;
    cursor: pointer;
}