@media (min-width: 350px) and (max-width: 400px) {

    .container-connection {
        width: 90%;
        height: auto;
        margin: 100px 20px;
        padding: 20px 20px 30px 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 12px;
        color: #333;
    }
}

@media (min-width: 400px) {

    .container-connection {
        position: absolute;
        width: 350px;
        height: auto;
        padding: 20px 20px 30px 20px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 12px;
        color: #333;
    }  
}

.container-connection h2 {
    height: 50px;
    width: 100%;
    margin-bottom: 30px;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-connection .input-box {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
}

.container-connection .input-box label {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 2px;
    margin-left: 2px;
}

.container-connection .input-box input {
    width: 100%;
    height: 40px;
    padding: 10px;
    border-radius: 6px;
    outline: none;
    font-size: 14px;
    border: 1px solid #cfcfcf;
}

.container-connection .container-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-connection .container-btn button {
    width: 130px;
    height: 35px;
    margin-top: 50px;
    border-radius: 6px;
    background-color: #333;
    color: #ffffff;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    outline: none;
    border: none;
    cursor: pointer;
}

.container-connection .container-btn button:hover {
    background-color: #414141;
}