.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
}

.App main {
    flex: 1;
}
