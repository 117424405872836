.background-container-hours {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (min-width: 350px) and (max-width: 600px) {

    .container-hours .container-users-choice {
        width: 100%;
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .container-hours h1 {
        text-align: center;
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }

}

@media (min-width: 600px) {

    .container-hours .container-users-choice {
        width: 100%;
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    
    .container-hours h1 {
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }
}

.container-hours {
    max-width: 600px;
    width: 100%;
    margin: 100px 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: #ffffff;
    border: 1px solid #cfcfcf;
    z-index: 1;
}

.container-hours .container-users-choice .choice-users {
    width: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.container-hours .container-users-choice .choice-users .icon {
    font-size: 18px;
    cursor: pointer;
}

.container-hours h1 {
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.container-hours table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.container-hours thead th {
    background-color: #f4f4f4;
    padding: 10px;
}

.container-hours tbody td {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    font-size: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    cursor: default;
}

.container-hours tbody td .icon-modify {
    font-size: 16px;
    color: #333;
    cursor: pointer;
}

.container-hours tbody td .icon-modify:hover {
    color: #464646;
}

.container-hours tbody td:nth-child(1) {
    width: 30%;
    text-align: left;
}

.container-hours tbody td:nth-child(2) {
    width: 30%;
    text-align: center;
}

.container-hours tbody td:nth-child(3) {
    width: 30%;
    text-align: center;
}

.container-hours tbody td:nth-child(4) {
    width: 10%;
    text-align: right;
}