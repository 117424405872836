footer {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    border-top: 1px solid #cfcfcf;
    background-color: #f5f5f5;
    font-size: 95%;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}