.background-container-tasks {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (min-width: 350px) and (max-width: 610px) {
        
    .container-tasks {
        max-width: 350px;
        width: 100%;
        margin: 100px 20px;
        padding: 20px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        z-index: 1;
    }

    .container-tasks .container-add-btn {
        width: 100%;
        margin: 10px 0 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container-tasks .container-add-btn button {
        background-color: #333;
        border: none;
        color: #ffffff;
        padding: 8px 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        border-radius: 6px;
        cursor: pointer;
    }

    .container-tasks .container-add-btn button:hover {
        background-color: #202020;
    }

    .container-tasks h1 {
        text-align: center;
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }

    .container-tasks  .container-box {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        width: 100%;
        justify-items: center; 
    }

    .container-tasks .box {
        position: relative;
        width: 100%;
        max-width: 255px; 
        aspect-ratio: 1 / 1;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
    }

    .container-tasks .box h2 {
        width: 60%;
        text-align: start;
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: default;
        word-wrap: break-word;
        hyphens: auto;
        line-height: 1.2;
    }

    .container-tasks .box h3 {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        cursor: default;
    }

    .container-tasks .box .container-icon {
        position: absolute;
        border-left: 1px solid #cfcfcf;
        border-bottom: 1px solid #cfcfcf;
        border-bottom-left-radius: 10px;
        right: 0px;
        top: 0px;
        display: flex;
        flex-direction: column;
    }

    .container-tasks .box .container-icon .visibility {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;
    }

    .container-tasks .box .container-icon .visibility .icon {
        font-size: 18px;
        color: #888888;
    }

    .container-tasks .box .container-icon .status {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-tasks .box .container-icon .status .icon {
        font-size: 18px;
        color: #6fbe7a;
        cursor: pointer;
    }

    .container-tasks .box .container-btn {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }

    .container-tasks .box .container-btn .onedrive {
        width: 60%;
        margin-bottom: 15px;
        border-radius: 4px;
        text-decoration: none;
        color: #333;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: pointer;
    }

    .container-tasks .box .container-btn span {
        width: 60%;
        height: 38px;
        margin-bottom: 10px;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
    }

    .container-tasks .box .container-btn span.in-progress {
        border: 2px solid #ffb157;
        color: #ffb157;
    }

    .container-tasks .box .container-btn span.closed {
        border: 2px solid #44d173;
        color: #44d173;
    }

    .container-tasks .box .container-btn button {
        width: 60%;
        height: 38px;
        border: none;
        border-radius: 6px;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        background-color: #333;
        color: #ffffff;
        cursor: pointer;
    }

    .container-tasks .box .container-btn button:hover {
        background-color: #202020;
    }

}

@media (min-width: 610px) and (max-width: 850px) {
        
    .container-tasks {
        max-width: 570px;
        width: 100%;
        margin: 100px 20px;
        padding: 20px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        z-index: 1;
    }

    .container-tasks .container-add-btn {
        width: 100%;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .container-tasks .container-add-btn button {
        background-color: #333;
        border: none;
        color: #ffffff;
        padding: 8px 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        border-radius: 6px;
        cursor: pointer;
    }

    .container-tasks .container-add-btn button:hover {
        background-color: #202020;
    }

    .container-tasks h1 {
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }

    .container-tasks  .container-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        width: 100%;
    }

    .container-tasks .box {
        position: relative;
        width: 255px;
        aspect-ratio: 1 / 1;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
    }

    .container-tasks .box h2 {
        width: 60%;
        text-align: start;
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: default;
        word-wrap: break-word;
        hyphens: auto;
        line-height: 1.2;
    }

    .container-tasks .box h3 {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        cursor: default;
    }

    .container-tasks .box .container-icon {
        position: absolute;
        border-left: 1px solid #cfcfcf;
        border-bottom: 1px solid #cfcfcf;
        border-bottom-left-radius: 10px;
        right: 0px;
        top: 0px;
        display: flex;
        flex-direction: column;
    }

    .container-tasks .box .container-icon .visibility {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;
    }

    .container-tasks .box .container-icon .visibility .icon {
        font-size: 18px;
        color: #888888;
    }

    .container-tasks .box .container-icon .status {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-tasks .box .container-icon .status .icon {
        font-size: 18px;
        color: #6fbe7a;
        cursor: pointer;
    }

    .container-tasks .box .container-btn {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }

    .container-tasks .box .container-btn .onedrive {
        width: 60%;
        margin-bottom: 15px;
        border-radius: 4px;
        text-decoration: none;
        color: #333;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: pointer;
    }

    .container-tasks .box .container-btn span {
        width: 60%;
        height: 38px;
        margin-bottom: 10px;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
    }

    .container-tasks .box .container-btn span.in-progress {
        border: 2px solid #ffb157;
        color: #ffb157;
    }

    .container-tasks .box .container-btn span.closed {
        border: 2px solid #44d173;
        color: #44d173;
    }

    .container-tasks .box .container-btn button {
        width: 60%;
        height: 38px;
        border: none;
        border-radius: 6px;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        background-color: #333;
        color: #ffffff;
        cursor: pointer;
    }

    .container-tasks .box .container-btn button:hover {
        background-color: #202020;
    }
}

@media (min-width: 850px) and (max-width: 1150px) {

    .container-tasks {
        max-width: 850px;
        width: 100%;
        margin: 100px 20px;
        padding: 20px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        z-index: 1;
    }

    .container-tasks .container-add-btn {
        width: 100%;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .container-tasks .container-add-btn button {
        background-color: #333;
        border: none;
        color: #ffffff;
        padding: 8px 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        border-radius: 6px;
        cursor: pointer;
    }

    .container-tasks .container-add-btn button:hover {
        background-color: #202020;
    }

    .container-tasks h1 {
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }

    .container-tasks  .container-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        width: 100%;
    }

    .container-tasks .box {
        position: relative;
        width: 255px;
        aspect-ratio: 1 / 1;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
    }

    .container-tasks .box h2 {
        width: 60%;
        text-align: start;
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: default;
        word-wrap: break-word;
        hyphens: auto;
        line-height: 1.2;
    }

    .container-tasks .box h3 {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        cursor: default;
    }

    .container-tasks .box .container-icon {
        position: absolute;
        border-left: 1px solid #cfcfcf;
        border-bottom: 1px solid #cfcfcf;
        border-bottom-left-radius: 10px;
        right: 0px;
        top: 0px;
        display: flex;
        flex-direction: column;
    }

    .container-tasks .box .container-icon .visibility {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;
    }

    .container-tasks .box .container-icon .visibility .icon {
        font-size: 18px;
        color: #888888;
    }

    .container-tasks .box .container-icon .status {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-tasks .box .container-icon .status .icon {
        font-size: 18px;
        color: #6fbe7a;
        cursor: pointer;
    }

    .container-tasks .box .container-btn {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }

    .container-tasks .box .container-btn .onedrive {
        width: 60%;
        margin-bottom: 15px;
        border-radius: 4px;
        text-decoration: none;
        color: #333;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: pointer;
    }

    .container-tasks .box .container-btn span {
        width: 60%;
        height: 38px;
        margin-bottom: 10px;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
    }

    .container-tasks .box .container-btn span.in-progress {
        border: 2px solid #ffb157;
        color: #ffb157;
    }

    .container-tasks .box .container-btn span.closed {
        border: 2px solid #44d173;
        color: #44d173;
    }

    .container-tasks .box .container-btn button {
        width: 60%;
        height: 38px;
        border: none;
        border-radius: 6px;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        background-color: #333;
        color: #ffffff;
        cursor: pointer;
    }

    .container-tasks .box .container-btn button:hover {
        background-color: #202020;
    }
}

@media (min-width: 1150px) and (max-width: 1440px) {
    
    .container-tasks {
        max-width: 1125px;
        width: 100%;
        margin: 100px 20px;
        padding: 20px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        z-index: 1;
    }

    .container-tasks .container-add-btn {
        width: 100%;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .container-tasks .container-add-btn button {
        background-color: #333;
        border: none;
        color: #ffffff;
        padding: 8px 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        border-radius: 6px;
        cursor: pointer;
    }

    .container-tasks .container-add-btn button:hover {
        background-color: #202020;
    }

    .container-tasks h1 {
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }

    .container-tasks  .container-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        width: 100%;
    }

    .container-tasks .box {
        position: relative;
        width: 255px;
        aspect-ratio: 1 / 1;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
    }

    .container-tasks .box h2 {
        width: 60%;
        text-align: start;
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: default;
        word-wrap: break-word;
        hyphens: auto;
        line-height: 1.2;
    }

    .container-tasks .box h3 {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        cursor: default;
    }

    .container-tasks .box .container-icon {
        position: absolute;
        border-left: 1px solid #cfcfcf;
        border-bottom: 1px solid #cfcfcf;
        border-bottom-left-radius: 10px;
        right: 0px;
        top: 0px;
        display: flex;
        flex-direction: column;
    }

    .container-tasks .box .container-icon .visibility {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;
    }

    .container-tasks .box .container-icon .visibility .icon {
        font-size: 18px;
        color: #888888;
    }

    .container-tasks .box .container-icon .status {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-tasks .box .container-icon .status .icon {
        font-size: 18px;
        color: #6fbe7a;
        cursor: pointer;
    }

    .container-tasks .box .container-btn {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }

    .container-tasks .box .container-btn .onedrive {
        width: 60%;
        margin-bottom: 15px;
        border-radius: 4px;
        text-decoration: none;
        color: #333;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: pointer;
    }

    .container-tasks .box .container-btn span {
        width: 60%;
        height: 38px;
        margin-bottom: 10px;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
    }

    .container-tasks .box .container-btn span.in-progress {
        border: 2px solid #ffb157;
        color: #ffb157;
    }

    .container-tasks .box .container-btn span.closed {
        border: 2px solid #44d173;
        color: #44d173;
    }

    .container-tasks .box .container-btn button {
        width: 60%;
        height: 38px;
        border: none;
        border-radius: 6px;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        background-color: #333;
        color: #ffffff;
        cursor: pointer;
    }

    .container-tasks .box .container-btn button:hover {
        background-color: #202020;
    }
}

@media (min-width: 1440px) {
    
    .container-tasks {
        max-width: 1400px;
        width: 100%;
        margin: 100px 20px;
        padding: 20px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        z-index: 1;
    }

    .container-tasks .container-add-btn {
        width: 100%;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .container-tasks .container-add-btn button {
        background-color: #333;
        border: none;
        color: #ffffff;
        padding: 8px 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        border-radius: 6px;
        cursor: pointer;
    }

    .container-tasks .container-add-btn button:hover {
        background-color: #202020;
    }

    .container-tasks h1 {
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }

    .container-tasks  .container-box {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
        width: 100%;
    }

    .container-tasks .box {
        position: relative;
        width: 100%;
        aspect-ratio: 1 / 1;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
    }

    .container-tasks .box h2 {
        width: 60%;
        text-align: start;
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: default;
        word-wrap: break-word;
        hyphens: auto;
        line-height: 1.2;
    }

    .container-tasks .box h3 {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        cursor: default;
    }

    .container-tasks .box .container-icon {
        position: absolute;
        border-left: 1px solid #cfcfcf;
        border-bottom: 1px solid #cfcfcf;
        border-bottom-left-radius: 10px;
        right: 0px;
        top: 0px;
        display: flex;
        flex-direction: column;
    }

    .container-tasks .box .container-icon .visibility {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;
    }

    .container-tasks .box .container-icon .visibility .icon {
        font-size: 18px;
        color: #888888;
    }

    .container-tasks .box .container-icon .status {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container-tasks .box .container-icon .status .icon {
        font-size: 18px;
        color: #6fbe7a;
        cursor: pointer;
    }

    .container-tasks .box .container-btn {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }

    .container-tasks .box .container-btn .onedrive {
        width: 60%;
        margin-bottom: 15px;
        border-radius: 4px;
        text-decoration: none;
        color: #333;
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        cursor: pointer;
    }

    .container-tasks .box .container-btn span {
        width: 60%;
        height: 38px;
        margin-bottom: 10px;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
    }

    .container-tasks .box .container-btn span.in-progress {
        border: 2px solid #ffb157;
        color: #ffb157;
    }

    .container-tasks .box .container-btn span.closed {
        border: 2px solid #44d173;
        color: #44d173;
    }

    .container-tasks .box .container-btn button {
        width: 60%;
        height: 38px;
        border: none;
        border-radius: 6px;
        margin-bottom: 10px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        background-color: #333;
        color: #ffffff;
        cursor: pointer;
    }

    .container-tasks .box .container-btn button:hover {
        background-color: #202020;
    }
}