.background-container-users {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (min-width: 350px) and (max-width: 600px) {

    .container-users tbody td .container-permission {
        width: 105px;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .container-users h1 {
        text-align: center;
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }

    .container-users .container-add-btn {
        width: 100%;
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

@media (min-width: 600px) {

    .container-users .container-add-btn {
        width: 100%;
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    
    .container-users h1 {
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }

    .container-users tbody td .container-permission {
        width: 175px;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.container-users {
    max-width: 600px;
    width: 100%;
    margin: 100px 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: #ffffff;
    border: 1px solid #cfcfcf;
    z-index: 1;
}

.container-users .container-add-btn button {
    background-color: #333;
    border: none;
    color: #ffffff;
    padding: 8px 10px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
}

.container-users .container-add-btn button:hover {
    background-color: #202020;
}

.container-users table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.container-users thead th {
    background-color: #f4f4f4;
    padding: 10px;
}

.container-users tbody td {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    font-size: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    cursor: default;
}

.container-users tbody td .icon {
    font-size: 20px;
    cursor: pointer;
}

.container-users tbody td .icon-delete {
    font-size: 25px;
    color: #cf5a5a;
    cursor: pointer;
}

.container-users tbody td .icon-delete:hover {
    color: #e24949;
}

.container-users tbody td:nth-child(1) {
    width: 35%;
    text-align: left;
}

.container-users tbody td:nth-child(2) {
    width: 25%;
}

.container-users tbody td:nth-child(3) {
    width: 20%;
    text-align: right;
}